/* tslint:disable */
/* eslint-disable */
/**
 * proto/server.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1LeaderboardEntry } from './V1LeaderboardEntry';
import {
    V1LeaderboardEntryFromJSON,
    V1LeaderboardEntryFromJSONTyped,
    V1LeaderboardEntryToJSON,
} from './V1LeaderboardEntry';

/**
 * GetLeaderboardResponse contains the leaderboard of joke models.
 * @export
 * @interface V1GetLeaderboardResponse
 */
export interface V1GetLeaderboardResponse {
    /**
     * 
     * @type {Array<V1LeaderboardEntry>}
     * @memberof V1GetLeaderboardResponse
     */
    entries?: Array<V1LeaderboardEntry>;
}

/**
 * Check if a given object implements the V1GetLeaderboardResponse interface.
 */
export function instanceOfV1GetLeaderboardResponse(value: object): value is V1GetLeaderboardResponse {
    return true;
}

export function V1GetLeaderboardResponseFromJSON(json: any): V1GetLeaderboardResponse {
    return V1GetLeaderboardResponseFromJSONTyped(json, false);
}

export function V1GetLeaderboardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetLeaderboardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'entries': json['entries'] == null ? undefined : ((json['entries'] as Array<any>).map(V1LeaderboardEntryFromJSON)),
    };
}

export function V1GetLeaderboardResponseToJSON(value?: V1GetLeaderboardResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'entries': value['entries'] == null ? undefined : ((value['entries'] as Array<any>).map(V1LeaderboardEntryToJSON)),
    };
}

